import React, { Fragment, useState, useEffect, useRef } from "react";
import "./FitOutProcess.css";
import { motion } from "framer-motion";
import FitHeader from "../components/FitHeader";
import FitBg from "../Assets/fit-out/fit-outBg.jpg";
import FitBgDark from "../Assets/fit-out/fit-outBg-dark.jpg";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import BgParticles from "../components/BgPraticles";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import axios from "axios";
import { RxTriangleRight } from "react-icons/rx";
import { GiCheckMark } from "react-icons/gi";
import Lottie from "lottie-react";
import AnimationPhone from "../jsondata/fitHaederANI.json";
import { IoStar } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";

const fitH1Variants = {
  hidden: {
    y: -20,
    x: -5,
    scaleX: 0.5,
  },
  visible: {
    y: 0,
    x: 0,
    scaleX: 1,
  },
};
export default function FitOutProcess() {
  const fitHeading = "Fit Out Process";
  const [homebgChange, setHomebgChange] = useState(true);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  });
  return !loader ? (
    <Loader />
  ) : (
    <Fragment>
      <FitHeader
        homebgChange={homebgChange}
        setHomebgChange={setHomebgChange}
      />
      <section className="Fit-out-process" id="Fit-Home">
        <img src={homebgChange ? FitBg : FitBgDark} className="FitHeaderBg" />
        <div></div>
        <div className="Fit-out-process-heading ">
          <div className="Fit-out-process-heading-animation">
            <Lottie animationData={AnimationPhone} loop={true} />
          </div>
          {fitHeading.split("").map((heading, index) => {
            return heading === " " ? (
              <h1>&nbsp;</h1>
            ) : (
              <motion.h1
                variants={fitH1Variants}
                initial="hidden"
                animate="visible"
                transition={{
                  type: "spring",
                  duration: 2,
                  delay: index * 0.05,
                  stiffness: 300,
                }}
              >
                {heading}
              </motion.h1>
            );
          })}
        </div>
      </section>
      <FitForm />
    </Fragment>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function FitForm() {
  const fileInputRef = useRef(null);

  const childPRef = useRef();
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [filenName, setFilesName] = useState([]);
  const [requestTypeNo, setRequestTypeNo] = useState(null);
  const [requestTypeOriginal, setRequestTypeOriginal] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownPrioty, setShowDropdownPrioty] = useState(false);
  const [showDropdownParentCase, setShowDropdownParentCase] = useState(false);
  const [showDropdownUnitId, setShowDropdownUnitId] = useState(false);
  const [showDropdownCaseCategory, setshowDropdownCaseCategory] =
    useState(false);
  const [showDropdownRequestDocument, setshowDropdownRequestDocument] =
    useState(false);
  const [showDropdownCaseCategoryChild, setShowDropdownCaseCategoryChild] =
    useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [priority, setPriority] = useState(null);
  const [unitId, setunitId] = useState(null);
  const [caseCategory, setCaseCategory] = useState(null);
  const [parentCase, setParentCase] = useState(null);
  const [requestDocument, setRequestDocument] = useState(null);
  const [selectedRequestDocIds, setSelectedRequestDocIds] = useState([]);
  const [parentCaseRecId, setparentCaseRecId] = useState(null);
  const [caseCategoryRecid, setCaseCategoryRecid] = useState(null);
  const [enableUnitId, setEnableUnitId] = useState(false);
  const [error, setError] = useState(null);
  const [placeholderError, setPlaceholderError] = useState("");
  const [placeholderErrorShow, setPlaceholderErrorShow] = useState(false);
  const [fitProspectData, setFitProspectData] = useState(null);
  const [triggerFileUpload, setTriggerFileUpload] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState({
    Name: "",
    RequestType: "",
    UnitId: "",
    CaseCategory: "",
    Description: "",
    ParentCase: "",
    Priority: "",
    RequestDocumentType: "",
  });

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axios.get(
          "https://app-qdk5soz5sq-uc.a.run.app/api/data/all"
        );
        setPriority(response.data.fitProperty.value);
        setunitId(response.data.fitUnitId.value);
        setCaseCategory(response.data.fitcaseCategory.value);
        setParentCase(response.data.fitparentCase.value);
        setRequestDocument(response.data.fitrequestDocument.value);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAllData();
  }, []);
  useEffect(() => {
    if (selectedRequestDocIds.length > 0) {
      fileInputRef.current.click();
    }
  }, [inputValue.RequestDocumentType]);

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const newFiles = await Promise.all(
      selectedFiles.map(async (file) => {
        const base64 = await convertBase64(file);
        return { fileName: file.name, base64 };
      })
    );

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64String = fileReader.result.split(",")[1];
        resolve(base64String);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    function unitIdDropdownHandler() {
      if (!unitId || !inputValue.Name || !inputValue.RequestType) {
        setEnableUnitId(true);
        setShowDropdownUnitId(false);
      } else if (
        unitId.some((unit) => unit.CustomerAccount === inputValue.Name)
      ) {
        setEnableUnitId(false);
        setShowDropdownUnitId(true);
        setPlaceholderError("");
        setPlaceholderErrorShow(false);
      } else if (
        unitId.some((unit) => unit.CustomerAccount !== inputValue.Name)
      ) {
        setPlaceholderError("Please Enter Valid Id");
        setPlaceholderErrorShow(true);
      } else {
        setShowDropdownUnitId(false);
      }
    }

    unitIdDropdownHandler();
  }, [inputValue.Name, inputValue.RequestType, unitId]);

  function inputValueHandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setInputValue({ ...inputValue, [name]: value });
  }
  function addValueToInput1(requestTypeValue, no, original) {
    setRequestTypeNo(no);
    setRequestTypeOriginal(original);
    setInputValue({ ...inputValue, RequestType: requestTypeValue });
    setShowDropdown(false);
    setShowDropdownUnitId(false);
  }

  function addValueToInputPriority(priorityValue) {
    setInputValue({ ...inputValue, Priority: priorityValue });
    setShowDropdown(false);
    setShowDropdownPrioty(false);
  }
  function addValueToInputUnitId(unitIdValue) {
    setInputValue({ ...inputValue, UnitId: unitIdValue });
    setShowDropdown(false);
    setShowDropdownPrioty(false);
    setShowDropdownUnitId(false);
  }
  function addValueToInputCaseCategory(caseCategoryValue, caseCategoryRecid) {
    setInputValue({ ...inputValue, CaseCategory: caseCategoryValue });
    setCaseCategoryRecid(caseCategoryRecid);
    setshowDropdownCaseCategory(false);
  }
  function addValueToInputParentCase(parentCaseId, parentrecid) {
    setInputValue({ ...inputValue, ParentCase: parentCaseId });
    setparentCaseRecId(parentrecid);
    setShowDropdownParentCase(false);
  }
  function addValueToInputRequestDocumnt(requestDocId) {
    setInputValue({ ...inputValue, RequestDocumentType: requestDocId });
    setSelectedRequestDocIds((prev) => [...prev, requestDocId]);
    setshowDropdownRequestDocument(false);
  }
  console.log(selectedRequestDocIds);
  function showDropdownHandler() {
    setShowDropdown(true);
    setShowDropdownPrioty(false);
    setShowDropdownUnitId(false);
    setshowDropdownCaseCategory(false);
    setShowDropdownParentCase(false);
    setshowDropdownRequestDocument(false);
  }
  function showDropdownPriorityHandler() {
    setShowDropdown(false);
    setShowDropdownPrioty(true);
    setShowDropdownUnitId(false);
    setshowDropdownCaseCategory(false);
    setShowDropdownParentCase(false);
    setshowDropdownRequestDocument(false);
  }
  function showDropdownUnitIdHandler() {
    setShowDropdown(false);
    setShowDropdownPrioty(false);
    setshowDropdownCaseCategory(false);
    setShowDropdownParentCase(false);
    setshowDropdownRequestDocument(false);
    if (inputValue.Name && inputValue.RequestType) {
      setShowDropdownUnitId(true);
    }
  }
  function showDropdownParentCaseHandler() {
    setShowDropdownParentCase(true);
    setShowDropdown(false);
    setShowDropdownPrioty(false);
    setshowDropdownCaseCategory(false);
    setShowDropdownUnitId(false);
    setshowDropdownRequestDocument(false);
  }
  function showDropdownRequestDocumentHandler() {
    setshowDropdownRequestDocument(true);
    setShowDropdownParentCase(false);
    setShowDropdown(false);
    setShowDropdownPrioty(false);
    setshowDropdownCaseCategory(false);
    setShowDropdownUnitId(false);
  }
  function showDropdownRemoveHandler() {
    setShowDropdown(false);
    setShowDropdownPrioty(false);
    setShowDropdownUnitId(false);
    setshowDropdownCaseCategory(false);
    setShowDropdownParentCase(false);
    setshowDropdownRequestDocument(false);
  }
  function caseCategoryParentHandler(index) {
    setSelectedCategoryIndex(index === selectedCategoryIndex ? null : index);
  }
  function showDropdownCaseCategoryHandler() {
    setshowDropdownCaseCategory(true);
    setShowDropdown(false);
    setShowDropdownPrioty(false);
    setShowDropdownUnitId(false);
    setShowDropdownParentCase(false);
    setshowDropdownRequestDocument(false);
  }

  useEffect(() => {
    if (fitProspectData) {
      const sendProspectData = async () => {
        setIsSubmitting(true);
        try {
          const response = await axios.post(
            "https://app-qdk5soz5sq-uc.a.run.app/api/fitcreate-prospects",
            fitProspectData
          );

          toast.success("Create & Sent successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          await uploadFiles(response.data);
        } catch (error) {
          console.error(
            "Error:",
            error.response?.data || error.message || error
          );
          console.log("An error occurred.");
          toast.error("An error occurred while uploading files.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } finally {
          setInputValue({
            Name: "",
            RequestType: "",
            UnitId: "",
            CaseCategory: "",
            Description: "",
            ParentCase: "",
            Priority: "",
            RequestDocumentType: "",
          });
          setFitProspectData(null);
          setFiles([]);
        }
      };

      sendProspectData();
    }
  }, [fitProspectData, files]);

  const uploadFiles = async (busRelAccount) => {
    try {
      const filesData = files.map((file, index) => {
        const baseFileName = file.fileName.split(".").slice(0, -1).join(".");

        const docId = selectedRequestDocIds[index] || "DefaultDocId";

        return {
          FileName: baseFileName,
          dataAreaId: "USMF",
          DocumentAttachmentTypeCode: "File",
          AttachmentDescription: baseFileName,
          FileType: file.fileName.split(".").pop(),
          CaseId: busRelAccount,
          BIMFitOutReqDocId: docId,
          Notes: "",
          Attachment: file.base64,
        };
      });

      console.log(filesData);

      for (const fileData of filesData) {
        console.log("Sending file data:", fileData);

        await axios.post(
          "https://app-qdk5soz5sq-uc.a.run.app/api/fitupload-files",
          fileData
        );

        toast.success(`${fileData.FileName} Sent successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message || error
      );
      toast.error("An error occurred while uploading files.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    setSelectedRequestDocIds([]);
  };

  const handleFileCheckAndUpdate = () => {
    if (selectedRequestDocIds.length < files.length) {
      console.log(
        "Files and request document IDs length do not match. Reducing the last file."
      );

      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        while (updatedFiles.length > selectedRequestDocIds.length) {
          updatedFiles.pop(); // Reduce the last file, not the first
        }
        return updatedFiles;
      });
    } else {
      console.log("Files and request document IDs length match.");
    }
  };

  const removePhotoHandler = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    setSelectedRequestDocIds((prevSelected) =>
      prevSelected.filter((_, index) => index !== indexToRemove)
    );
  };
  const prevFilesLength = useRef(files.length);
  useEffect(() => {
    if (!isSubmitting) {
      if (files.length > prevFilesLength.current) {
        toast.success("Uploaded Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        handleFileCheckAndUpdate();
      } else if (files.length < prevFilesLength.current) {
        toast.success("Removed Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }

    prevFilesLength.current = files.length;
  }, [files, isSubmitting]);

  async function formSubmitHandler(e) {
    e.preventDefault();
    const filteredDocuments = requestDocument.filter(
      (document) => document.ProcessReqType === requestTypeOriginal
    );
    if (selectedRequestDocIds.length !== filteredDocuments.length) {
      toast.warn("Please Upload all the documents!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    if (
      !inputValue.Name.trim() ||
      !inputValue.RequestType.trim() ||
      !inputValue.UnitId.trim() ||
      !inputValue.CaseCategory.trim() ||
      !inputValue.RequestDocumentType.trim()
    ) {
      toast.warn("Please Enter Your Details!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      try {
        const newProspectData = {
          customerid: inputValue.Name,
          reqtype: requestTypeNo,
          unitid: inputValue.UnitId,
          casecategory: caseCategoryRecid,
          description: inputValue.Description || "",
          parentcaseRecId: parentCaseRecId || 0,
          priority: inputValue.Priority || "",
          company: "USMF",
        };
        console.log(newProspectData);
        setFitProspectData(newProspectData);
      } catch (error) {
        console.error("Error setting prospect data:", error);
        toast.error("Failed to prepare prospect data.");
      }
      e.target.reset();

      setRequestTypeOriginal(null);
      setPlaceholderError("");
      setPlaceholderErrorShow(false);
    }
  }
  return (
    <Fragment>
      <section className="Fit-Form-Section" id="FitOut">
        <BgParticles />
        <span className="form-ball1"></span>
        <span className="form-ball2"></span>
        <span className="form-ball3"></span>
        <span className="form-ball4"></span>
        <div className="container Fit-Form-Container">
          {/* <div className="form-rotate-spinner"></div> */}

          <form onSubmit={formSubmitHandler}>
            <div className="Fit-Name input-groups">
              {placeholderErrorShow && (
                <div className="alert-Fit-Name">
                  <p className="text-center">{placeholderError}</p>
                </div>
              )}
              <input
                type="text"
                value={inputValue.Name}
                name="Name"
                id="name"
                placeholder={placeholderError}
                onClick={showDropdownRemoveHandler}
                onChange={inputValueHandler}
                required
              />
              <label htmlFor="name">Customer Id</label>
            </div>
            <div className="Fit-Request-Type input-groups">
              <input
                type="text"
                value={inputValue.RequestType}
                name="RequestType"
                id="requestType"
                onClick={showDropdownHandler}
                onChange={inputValueHandler}
                required
              />
              <label htmlFor="requestType">Request Type</label>
              {showDropdown && (
                <div className="drop-down">
                  <table className="table">
                    <tbody>
                      <tr
                        onClick={() =>
                          addValueToInput1(
                            "Tenant Document Submission",
                            "3",
                            "TenantDocSubmission"
                          )
                        }
                      >
                        <td>Tenant Document Submission</td>
                      </tr>
                      <tr
                        onClick={() =>
                          addValueToInput1(
                            "Work Permit Procedure",
                            "4",
                            "WorkPermitProcedure"
                          )
                        }
                      >
                        <td>Work Permit Procedure</td>
                      </tr>
                      <tr
                        onClick={() =>
                          addValueToInput1(
                            "Tenant Proof Submission",
                            "5",
                            "TenantProofSubmission"
                          )
                        }
                      >
                        <td>Tenant Proof Submission</td>
                      </tr>
                      <tr
                        onClick={() =>
                          addValueToInput1(
                            "Request For Inspection",
                            "6",
                            "InspectionRequest"
                          )
                        }
                      >
                        <td>Request For Inspection</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Fit-Uit-Id input-groups">
              <input
                type="text"
                value={inputValue.UnitId}
                name="UnitId"
                id="uit-Id"
                onClick={showDropdownUnitIdHandler}
                onChange={inputValueHandler}
                readOnly={enableUnitId}
                required
              />
              <label htmlFor="uitId">Unit Id</label>
              {showDropdownUnitId && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Property Id</th>
                        <th>Place Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unitId &&
                        unitId
                          .filter(
                            (unit) => unit.CustomerAccount === inputValue.Name
                          )
                          .map((unit, index) => {
                            return (
                              <tr
                                onClick={() =>
                                  addValueToInputUnitId(unit.PropertyId)
                                }
                                key={unit["@odata.etag"] || index}
                              >
                                <td>{unit.PropertyId}</td>
                                <td>{unit.PlaceName}</td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Fit-Case-Category input-groups">
              <input
                type="text"
                value={inputValue.CaseCategory}
                name="CaseCategory"
                id="caseCategory"
                onClick={showDropdownCaseCategoryHandler}
                onChange={inputValueHandler}
                required
              />
              <label htmlFor="caseCategory">Case Category</label>
              {showDropdownCaseCategory && (
                <div className="drop-down">
                  {caseCategory &&
                    caseCategory
                      .reduce((acc, caseCat) => {
                        const existingCategory = acc.find(
                          (item) => item.CategoryType === caseCat.CategoryType
                        );

                        if (existingCategory) {
                          existingCategory.children.push(caseCat);
                        } else {
                          acc.push({
                            CategoryType: caseCat.CategoryType,
                            children: [caseCat],
                          });
                        }

                        return acc;
                      }, [])
                      .map((category, index) => (
                        <div className="drop-down-parent" key={index}>
                          <h5
                            onClick={() => caseCategoryParentHandler(index)}
                            className={
                              selectedCategoryIndex === index ? "rotated" : ""
                            }
                          >
                            <RxTriangleRight className="drop-down-parent-h5-triIcon" />
                            {category.CategoryType}
                          </h5>

                          {selectedCategoryIndex === index && (
                            <div ref={childPRef} className="drop-down-child">
                              {category.children.map((child, childIndex) => (
                                <p
                                  key={childIndex}
                                  onClick={() =>
                                    addValueToInputCaseCategory(
                                      child.CaseCategory,
                                      child.CaseCategoryRecid
                                    )
                                  }
                                  className="ps-3"
                                >
                                  {child.CaseCategory}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                </div>
              )}
            </div>
            <div className="Fit-Description input-groups">
              <input
                type="text"
                value={inputValue.Description}
                name="Description"
                id="description"
                onClick={showDropdownRemoveHandler}
                onChange={inputValueHandler}
              />
              <label htmlFor="description">Description</label>
            </div>
            <div className="Fit-Parent-Case input-groups">
              <input
                type="text"
                value={inputValue.ParentCase}
                name="ParentCase"
                id="parent-Case"
                onClick={showDropdownParentCaseHandler}
                onChange={inputValueHandler}
              />
              <label htmlFor="parent-Case">Parent Case</label>
              {showDropdownParentCase && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">Case Id</th>
                        <th className="text-center">Description</th>
                        <th className="text-center">Case Category</th>
                        <th className="text-center">Request Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parentCase &&
                        parentCase
                          .filter(
                            (parent) =>
                              inputValue.RequestType &&
                              inputValue.CaseCategory &&
                              parent.Customeraccount === inputValue.Name
                          )
                          .map((parent, index) => {
                            return (
                              <tr
                                key={parent["@odata.etag"] || index}
                                onClick={() =>
                                  addValueToInputParentCase(
                                    parent.CaseId,
                                    parent.Caserecid
                                  )
                                }
                              >
                                <td className="text-center">{parent.CaseId}</td>
                                <td className="text-center">
                                  {parent.Description
                                    ? parent.Description
                                    : "None"}
                                </td>
                                <td className="text-center">
                                  {inputValue.CaseCategory}
                                </td>
                                <td className="text-center">
                                  {inputValue.RequestType}
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Fit-Priority input-groups">
              <input
                type="text"
                value={inputValue.Priority}
                name="Priority"
                id="priority"
                onClick={showDropdownPriorityHandler}
                onChange={inputValueHandler}
              />
              <label htmlFor="priority">Priority</label>
              {showDropdownPrioty && (
                <div className="drop-down">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Priority</th>
                        <th>Description</th>
                        <th>Hot alert</th>
                      </tr>
                    </thead>
                    <tbody>
                      {priority &&
                        priority
                          .filter((prior) => {
                            const caseSensitive = true;

                            const inputValueTrimmed =
                              inputValue.Priority.trim();

                            if (inputValueTrimmed === "") {
                              return prior;
                            }

                            if (caseSensitive) {
                              const upperInput =
                                inputValueTrimmed.toUpperCase();
                              return (
                                prior.Description.toUpperCase().includes(
                                  upperInput
                                ) ||
                                prior.Priority.toUpperCase().includes(
                                  upperInput
                                ) ||
                                prior.MCRHotAlert.toUpperCase().includes(
                                  upperInput
                                )
                              );
                            } else {
                              const lowerInput =
                                inputValueTrimmed.toLowerCase();
                              return (
                                prior.Description.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                prior.Priority.toLowerCase().includes(
                                  lowerInput
                                ) ||
                                prior.MCRHotAlert.toLowerCase().includes(
                                  lowerInput
                                )
                              );
                            }
                          })
                          .reverse()
                          .map((prior, index) => {
                            return (
                              <tr
                                onClick={() =>
                                  addValueToInputPriority(prior.Priority)
                                }
                                key={prior["@odata.etag"] || index}
                              >
                                <td>{prior.Priority}</td>
                                <td>{prior.Description}</td>
                                <td>{prior.MCRHotAlert}</td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="Fit-Request-Document-Type input-groups">
              <input
                type="text"
                value={inputValue.RequestDocumentType}
                name="RequestDocumentType"
                id="request-Document-Type"
                onClick={showDropdownRequestDocumentHandler}
                onChange={inputValueHandler}
                required
              />
              <label htmlFor="request-Document-Type">
                Request Document Type
              </label>
              {showDropdownRequestDocument && (
                <div className="drop-down">
                  {requestDocument &&
                    requestDocument
                      .filter(
                        (document) =>
                          document.ProcessReqType === requestTypeOriginal
                      )
                      .map((document, index) => {
                        const isSelected = selectedRequestDocIds.includes(
                          document.ReqDocId
                        );

                        return (
                          <div
                            className={`drop-down-child ${
                              isSelected ? "selected" : ""
                            }`}
                            key={document.ReqDocId || index}
                            onClick={() => {
                              if (!isSelected) {
                                addValueToInputRequestDocumnt(
                                  document.ReqDocId
                                );
                              } else {
                                setInputValue({
                                  ...inputValue,
                                  RequestDocumentType: document.ReqDocId,
                                });
                                setshowDropdownRequestDocument(false);
                              }
                            }}
                          >
                            <p>{document.ReqDocId}</p>

                            {isSelected && (
                              <GiCheckMark className="drop-down-child-tickMark" />
                            )}
                          </div>
                        );
                      })}
                </div>
              )}
            </div>
            <div className="formFit-button-group">
              <div>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  sx={{
                    backgroundImage:
                      "linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #9098e8, #8396ee, #7394f5, #7182fb, #7a6dfd, #8b51fa, #a020f0);",
                    boxShadow:
                      "rgba(0, 0, 0, 0.3) 0px 19px 38px,rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    color: "#2b2c2c",
                    fontWeight: "bold",
                  }}
                  size="large"
                  className="form-upload-button"
                >
                  Upload files
                  <VisuallyHiddenInput
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                </Button>
              </div>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundImage:
                    "linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #9098e8, #8396ee, #7394f5, #7182fb, #7a6dfd, #8b51fa, #a020f0);",
                  boxShadow:
                    "rgba(0, 0, 0, 0.3) 0px 19px 38px,rgba(0, 0, 0, 0.22) 0px 15px 12px",
                  color: "#2b2c2c",
                  fontWeight: "bold",
                }}
                size="large"
                className="form-submit-button"
              >
                Create
              </Button>
            </div>
            <div className="d-flex show-file-name">
              <div>
                {selectedRequestDocIds &&
                  selectedRequestDocIds.map((docIds, index) => {
                    return (
                      <div className="selectedRequestDocIds-h6" key={index}>
                        <h6 className="selectedRequestDocIds-h6-h6">
                          <IoStar className="upload-files-show-star-h6" />
                          {docIds}
                        </h6>
                      </div>
                    );
                  })}
              </div>
              <div>
                {files &&
                  files.map((file, index) => {
                    const baseFileName = file.fileName
                      .split(".")
                      .slice(0, -1)
                      .join(".");
                    return (
                      <div key={index} className="upload-files-show-name">
                        <p className="upload-files-show-name-p">
                          <FaLongArrowAltRight className="upload-files-arrow" />
                          <IoStar className="upload-files-show-star" />
                          {baseFileName}
                          <MdCancel
                            className="upload-files-cancel"
                            onClick={() => removePhotoHandler(index)}
                          />
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </form>
        </div>
        <BgParticles />
      </section>
    </Fragment>
  );
}
