import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import Loader from "../loader/Loader";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const loginVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 120,
      duration: 1,
    },
  },
};
export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  });
  function passEyeHandler() {
    setEye(!eye);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "ram" && password === "ram123") {
      navigate("/Fit");
      setUsername("");
      setPassword("");
    } else {
      toast.warn("Please Enter Valid Id", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return !loader ? (
    <Loader />
  ) : (
    <div id="Login">
      <motion.div
        className="container login-container"
        variants={loginVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="login-box">
          <h2 className="signIn-heading">Sign In</h2>
          <form onSubmit={handleSubmit} className="login-box-form">
            <div className="username">
              <input
                type="text"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label>User Name</label>
            </div>
            <div className="password">
              <input
                type={!eye ? "password" : "text"}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label>Password</label>
              <span className="pass-span" onClick={passEyeHandler}>
                {!eye ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
            <button type="submit" className="btn-SignIn">
              Sign In
            </button>
            <div className="btn-ForgotPass-div">
              <a href="#" className=" btn btn-ForgotPass">
                Forgot Password
              </a>
            </div>
          </form>
        </div>
        {[...Array(50)].map((_, i) => (
          <span key={i} style={{ "--i": i }} className="login-spinner"></span>
        ))}
      </motion.div>
    </div>
  );
}
